import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38f1135c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersEditTopRight = _resolveComponent("OrdersEditTopRight")!
  const _component_OrdersEditModeSwitcher = _resolveComponent("OrdersEditModeSwitcher")!
  const _component_OrdersEditStatusBadge = _resolveComponent("OrdersEditStatusBadge")!
  const _component_OrdersEditCommon = _resolveComponent("OrdersEditCommon")!
  const _component_SelectServices = _resolveComponent("SelectServices")!
  const _component_OrdersEditWorkers = _resolveComponent("OrdersEditWorkers")!
  const _component_OrdersEditClient = _resolveComponent("OrdersEditClient")!
  const _component_OrdersEditPrice = _resolveComponent("OrdersEditPrice")!
  const _component_OrderFooterInfo = _resolveComponent("OrderFooterInfo")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    "is-content-loading": _ctx.isContentLoading,
    title: 
      _ctx.orderLoadingStatus === 'LOADING'
        ? `Загрузка заказа № ${_ctx.orderId}...`
        : `Заказ № ${_ctx.orderId}`
    ,
    tabs: _ctx.orderLoadingStatus === 'SUCCESS' ? _ctx.tabList : []
  }, _createSlots({
    footer: _withCtx(() => [
      (_ctx.orderLoadingStatus === 'SUCCESS')
        ? (_openBlock(), _createBlock(_component_FormFooter, {
            key: 0,
            "hide-submit-buttons": !['services', 'price'].includes(_ctx.selectedTabId),
            "is-submitting": _ctx.isSubmitting,
            "is-creation": false,
            onSubmit: _ctx.onSubmit
          }, {
            left: _withCtx(() => [
              (_ctx.selectedTabId !== 'services' && _ctx.order)
                ? (_openBlock(), _createBlock(_component_OrderFooterInfo, {
                    key: 0,
                    price: _ctx.order.userPrice,
                    minutes: _ctx.order.workTime
                  }, null, 8, ["price", "minutes"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_1))
            ]),
            _: 1
          }, 8, ["hide-submit-buttons", "is-submitting", "onSubmit"]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.order)
      ? {
          name: "topRight",
          fn: _withCtx(() => [
            _createVNode(_component_OrdersEditTopRight, {
              order: _ctx.order,
              onUpdate: _ctx.onUpdate
            }, null, 8, ["order", "onUpdate"])
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.order)
      ? {
          name: "topCenter",
          fn: _withCtx(() => [
            _createVNode(_component_OrdersEditModeSwitcher, {
              "order-id": _ctx.order.id,
              "is-edit-active": true
            }, null, 8, ["order-id"])
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.order)
      ? {
          name: "topBottom",
          fn: _withCtx(() => [
            _createVNode(_component_OrdersEditStatusBadge, { order: _ctx.order }, null, 8, ["order"])
          ]),
          key: "2"
        }
      : undefined,
    (!_ctx.isContentLoading)
      ? {
          name: "content",
          fn: _withCtx(() => [
            (_ctx.orderLoadingStatus === 'FAILURE')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" Ошибка загрузки заказа: " + _toDisplayString(_ctx.orderLoadingMessageError), 1)
                ], 64))
              : (_ctx.order)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", {
                      style: _normalizeStyle({ display: _ctx.selectedTabId !== 'common' ? 'none' : '' })
                    }, [
                      (_ctx.order)
                        ? (_openBlock(), _createBlock(_component_OrdersEditCommon, {
                            key: 0,
                            order: _ctx.order,
                            "onClick:services": _ctx.onServicesChangeClick
                          }, null, 8, ["order", "onClick:services"]))
                        : _createCommentVNode("", true)
                    ], 4),
                    _createElementVNode("div", {
                      style: _normalizeStyle({ display: _ctx.selectedTabId !== 'services' ? 'none' : '' })
                    }, [
                      _createVNode(_component_SelectServices, {
                        primary: _ctx.primaryServices,
                        secondary: _ctx.secondaryServices,
                        "primary-value": _ctx.primaryServicesValue,
                        "secondary-value": _ctx.secondaryServicesValue,
                        "delivery-keys-address": _ctx.deliveryKeysAddress,
                        type: _ctx.order?.type || 'ROOMS',
                        onChange: _ctx.onServicesChange
                      }, null, 8, ["primary", "secondary", "primary-value", "secondary-value", "delivery-keys-address", "type", "onChange"])
                    ], 4),
                    _createElementVNode("div", {
                      style: _normalizeStyle({ display: _ctx.selectedTabId !== 'workers' ? 'none' : '' })
                    }, [
                      _createVNode(_component_OrdersEditWorkers, { order: _ctx.order }, null, 8, ["order"])
                    ], 4),
                    _createElementVNode("div", {
                      style: _normalizeStyle({ display: _ctx.selectedTabId !== 'user' ? 'none' : '' })
                    }, [
                      _createVNode(_component_OrdersEditClient, { order: _ctx.order }, null, 8, ["order"])
                    ], 4),
                    _createElementVNode("div", {
                      style: _normalizeStyle({ display: _ctx.selectedTabId !== 'price' ? 'none' : '' })
                    }, [
                      (_ctx.priceValue && _ctx.order)
                        ? (_openBlock(), _createBlock(_component_OrdersEditPrice, {
                            key: 0,
                            order: _ctx.order,
                            value: _ctx.priceValue
                          }, null, 8, ["order", "value"]))
                        : _createCommentVNode("", true)
                    ], 4)
                  ], 64))
                : _createCommentVNode("", true)
          ]),
          key: "3"
        }
      : undefined
  ]), 1032, ["tab-id", "is-content-loading", "title", "tabs"]))
}