
import { defineComponent, PropType } from "vue";

import {
  FormFieldOptionsSwitcherTrueFalse,
  FieldValue,
  FormFieldSelect,
  FormField,
} from "@tager/admin-ui";

import { OrderFullInterface } from "@/modules/orders/typings";
import { PriceFormValues } from "@/modules/orders/views/orders-edit/OrdersEdit.vue";
import { OrderTariffOptions } from "@/modules/orders/enums/OrderTariff";

export default defineComponent({
  name: "OrdersEditPrice",
  components: {
    FormFieldOptionsSwitcherTrueFalse,
    FieldValue,
    FormField,
    FormFieldSelect,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    value: {
      type: Object as PropType<PriceFormValues>,
      required: false,
      default: undefined,
    },
  },
  emits: ["change"],
  setup(props) {
    return {
      tariffOptions: OrderTariffOptions,
      maxUsedBalance:
        +props.order.price.userBalance + props.order.price.usedBalance,
    };
  },
});
