import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-154751ab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-2" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "max-used-balance-label" }
const _hoisted_4 = { class: "right" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldOptionsSwitcherTrueFalse = _resolveComponent("FormFieldOptionsSwitcherTrueFalse")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormFieldOptionsSwitcherTrueFalse, {
        value: _ctx.value.isFree,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.isFree) = $event)),
        label: "Бесплатный заказ"
      }, null, 8, ["value"]),
      _createVNode(_component_FormFieldSelect, {
        value: _ctx.value.tariff,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.tariff) = $event)),
        label: "Тариф",
        options: _ctx.tariffOptions,
        clearable: false
      }, null, 8, ["value", "options"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.price,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.price) = $event)),
        label: "Цена",
        type: "number"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.discount,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.discount) = $event)),
        label: "Скидка, %,",
        type: "number"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.coefficient,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.coefficient) = $event)),
        label: "Коэффициент",
        type: "number"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.usedBalance,
        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.value.usedBalance) = $event)),
        label: "Списать с баланса, руб.",
        type: "number"
      }, null, 8, ["value"]),
      _createElementVNode("p", _hoisted_3, [
        _createTextVNode(" Максимальная сумма: "),
        _createElementVNode("b", null, _toDisplayString(_ctx.maxUsedBalance) + " руб.", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_FieldValue, {
          label: "Стоимость заказа:",
          value: Math.round(_ctx.order.price.base * 100) / 100 + ' руб.'
        }, null, 8, ["value"]),
        _createVNode(_component_FieldValue, {
          label: "Скидка / Наценка:",
          value: 
            Math.round((_ctx.order.price.user - _ctx.order.price.base) * 100) / 100 +
            ' руб.'
          
        }, null, 8, ["value"]),
        _createVNode(_component_FieldValue, {
          label: "Коэффициент на основные услуги:",
          value: String(_ctx.order.price.typeCoefficient)
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_FieldValue, {
          label: "К оплате:",
          value: Math.round(_ctx.order.price.user * 100) / 100 + ' руб.'
        }, null, 8, ["value"]),
        _createVNode(_component_FieldValue, {
          label: "Использованный баланс:",
          value: (_ctx.order.price.usedBalance ?? 0).toFixed(2) + ' руб.'
        }, null, 8, ["value"]),
        _createVNode(_component_FieldValue, {
          label: "Бонус:",
          value: (_ctx.order.price.bonus ?? 0).toFixed(2) + ' руб.'
        }, null, 8, ["value"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_FieldValue, {
          label: "Вознаграждение клинеров:",
          value: Math.round(_ctx.order.price.workers * 100) / 100 + ' руб.'
        }, null, 8, ["value"]),
        _createVNode(_component_FieldValue, {
          label: "Наше вознаграждение:",
          value: 
            Math.round((_ctx.order.price.user - _ctx.order.price.workers) * 100) / 100 +
            ' руб.'
          
        }, null, 8, ["value"])
      ])
    ])
  ]))
}